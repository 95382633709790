// import React from "react";
// import ReactDOM from "react-dom/client";
// import App from "./App";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);
import React from "react";
import { createRoot } from "react-dom"; // Use createRoot from the correct import path
import App from "./App";

const root = createRoot(document.getElementById("root"));
root.render(<App />);
