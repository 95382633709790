import React, { useEffect, useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import { getRegisterData } from "../services/user";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { userDetailsById } from "../services/user";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { baseUrls } from "../Constant/BaseUrl";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Skeleton } from "antd";
import Row from "react-bootstrap/Row";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import { invoice } from "../services/auth/invoice";
import { baseUrlPdf } from "../Constant/BaseUrl";
export const PaymentDetail = () => {
  const [record, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  //   const [filterRecord, setFilterRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(true);
  console.log(record, "record11");
  console.log(selectedRecord, "selectedRecord11");

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        console.log(record.name, "hhhhhhhnamme");
        return <>{record.name}</>;
      },
    },
    {
      key: "whatsapp_number",
      text: "Whatsapp Number",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "payment_date",
      text: "Payment Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        let date = record?.payment_date;
        date = new Date(date * 1000);
        return <div>{moment(date).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      key: "paid_amount",
      text: "Amount",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "payment_status",
      text: "Payment Status",
      className: "cust_name",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return record.payment_status == "Pending" ? (
          <>
            <i
              onClick={() => confirmAction("Verified", record.id)}
              className="fa fa-check"
              title="approve"
              style={{
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "green",
              }}
            ></i>
            <i
              onClick={() => confirmAction("Reject", record.id)}
              className="fa fa-times ms-3"
              title="reject"
              style={{
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "red",
              }}
            ></i>
          </>
        ) : (
          <h6
            className="m-0"
            style={{
              backgroundColor:
                record.payment_status == "Verified" ? "green" : "red",
              color: "#fff",

              textAlign: "center",
              borderRadius: "5px",

              padding: "6px",
            }}
          >
            {record.payment_status}
          </h6>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
    getData();
  }, []);
  const filterHandler = (type) => {
    if (type === "All") {
      setSelectedRecord(record);
    } else {
      const result = record.filter((item) => item.payment_status === type);

      setSelectedRecord(result);
    }
  };

  const getData = async () => {
    const config = localStorage.getItem("jwtToken");
    const res = await getRegisterData(config);
    if (res.status) {
      setRecord(res?.data);
      setSelectedRecord(res?.data);
    }
  };
  const handleShow = (id) => {
    setShow(true);
    getDataById(id);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedRecord(null);
  };
  const getDataById = async (id) => {
    setLoading(true);

    setTimeout(async () => {
      try {
        const config = localStorage.getItem("jwtToken");
        let data = {
          id,
        };

        const res = await userDetailsById(data, config);

        if (res?.status) {
          setSelectedRecord(res?.data);
        }
      } finally {
        setLoading(false);
      }
    }, 1000);
  };
  useEffect(() => {}, []);

  const confirmAction = (type, record) => {
    const message =
      type === "Verified"
        ? "Really want to approve ?"
        : "Really want to reject ?";
    console.log(type, "type111");
    confirmAlert({
      title: "Confirm to submit",
      message: message,

      buttons: [
        {
          label: "Yes",
          onClick: () => statusHandler(type, record),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const statusHandler = async (type, id) => {
    const config = localStorage.getItem("jwtToken");
    let data = {
      type,
      id,
    };
    const res = await invoice(data, config);
    console.log(res, "res");
    if (res.status) {
      toast.dismiss();
      toast.success(res.message);
      getData();
      window.open(`${baseUrlPdf}/uploads/${res?.data}`, "_blank");
    } else {
      toast.dismiss();
      toast.error(res.message);
      getData();
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>User Details</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      User Details
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User Details
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/dashboard">
                  Back
                </Link>
              </div>
              <Form.Select
                size="sm"
                style={{ width: "150px" }}
                onChange={(e) => filterHandler(e.target.value)}
              >
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="Verified">Verified</option>
                <option value="Reject">Reject</option>
              </Form.Select>
            </div>

            <div className="box">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                        records={selectedRecord}
                        columns={columns}
                        onPageChange={pageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
