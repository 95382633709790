import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";

function Navbar() {
  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <Link to={"/dashboard"} className="logo logo-dark">
          {/* <img src="/assets/images/logo.png" alt="" className="img-fluid" /> */}
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>

      <div className="ps-3 pe-3 pt-2 mb-3 ">
        <span className="d-flex align-items-center user-profile">
          {/* <img
            className="rounded-circle header-profile-user2"
            src="../../assets/images/users/avatar-1.png"
            alt="Header Avatar"
          /> */}
          <span className="text-start ms-xl-3">
            <small>Welcome</small>
            <span className="w100"> Admin</span>
          </span>
        </span>
      </div>
      <Sidebar />
    </div>
  );
}
export default Navbar;
