import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6">
            <p className="m-0 text-black">
            © {new Date().getFullYear()} 
            </p>
          </div>
      
        </div>
      </div>
    </footer>
  );
}
export default Footer;
