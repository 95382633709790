import * as opsService from "./Ops";

import { registerData, usetrDataById,dashboardDatas,registrationUpdates } from "../Constant/Api";

// const adminCreated = async (data, token) => {
//   let result = await opsService.postdata(adminAdd, data, token);

//   return result;
// };
const getRegisterData = async (data, token) => {
  let result = await opsService.getData(registerData, data, token);

  return result;
};

const userDetailsById = async (data, token) => {
  let result = await opsService.postdata(usetrDataById, data, token);

  return result;
};
const dashboardData = async (data, token) => {
  let result = await opsService.getData(dashboardDatas, data, token);

  return result;
};

const registrationUpdate = async (data, token) => {
  let result = await opsService.postdata(registrationUpdates, data, token);

  return result;
};

export { getRegisterData, userDetailsById,dashboardData,registrationUpdate };
