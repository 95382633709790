import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Widgets/Dashboard";
import { UserDetails } from "./Pages/UserDetails";
import { PaymentDetail } from "./Pages/PaymentDetail";
import { ToastContainer } from "react-toastify";
import { UserEdit } from "./Pages/partials/UserEdit";

function App() {
  return (
    <>
      <Router>
        {/* <button onClick={()=>{handlesocket()}}>Socket</button> */}
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/user-details" element={<UserDetails />} />
          <Route path="/payment-details" element={<PaymentDetail />} />
          <Route path="/user-update/:id" element={<UserEdit />} />
          {/* <Route path="/transaction" element={<Transaction />} />
          <Route path="/user-profile" element={<Userprofile />} />
          <Route path="/ido-request" element={<Ido />} />
          <Route path="/calendar" element={<Calendars />} />
          <Route path="/approve" element={<Approve />} />
          <Route path="/reject" element={<Reject />} />
          
          <Route path="/snapshot" element={<Snapshot />} />
          <Route path="/admin-details" element={<AdminDetails />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/ido-allocation" element={<IdoAllocation />} /> */}
        </Routes>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
}
export default App;
