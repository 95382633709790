import * as opsService from "../Ops";

import { invoices,} from "../../Constant/Api";

const invoice = async (data, token) => {
  let result = await opsService.postdata(invoices, data, token);

  return result;
};


export { invoice };