import * as opsService from "../Ops";

import { logins,} from "../../Constant/Api";

const login = async (data, token) => {
  let result = await opsService.postdata(logins, data, token);

  return result;
};


export { login };
