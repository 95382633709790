import React, { useEffect, useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { Link, useNavigate } from "react-router-dom";
import ReactDatatable from "@mkikets/react-datatable";
import { getRegisterData } from "../services/user";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { userDetailsById } from "../services/user";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { baseUrls } from "../Constant/BaseUrl";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Skeleton } from "antd";
import Row from "react-bootstrap/Row";
export const UserDetails = () => {
  const [record, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(true);
  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        console.log(record.name, "hhhhhhhnamme");
        return <>{record.name}</>;
      },
    },
    {
      key: "whatsapp_number",
      text: "Whatsapp Number",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "payment_date",
      text: "Payment Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        let date = record?.payment_date;
        date = new Date(date * 1000);
        return <div>{moment(date).format("DD/MM/YYYY")}</div>;
      },
    },
    {
      key: "paid_amount",
      text: "Amount",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "payment_status",
      text: "Payment Status",
      className: "cust_name",
      align: "left",
      sortable: true,
    },

    {
      key: "action",
      text: "Action",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <div className="d-flex">
            <span style={{ cursor: "pointer", fontSize: "22px" }} title="view" className=" btn btn-secondary icon_btn">
              <FiEye onClick={() => handleShow(record?.id)} />
            </span>
            <Link to={`/user-update/${record?.id}`}  className="ms-2 btn btn-secondary icon_btn">
              <i
                className="fa fa-edit"
                title="Edit"
              ></i>
            </Link>
          </div>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
    getData();
  }, []);

  const getData = async () => {
    const config = localStorage.getItem("jwtToken");
    const res = await getRegisterData(config);
    if (res.status) {
      setRecord(res?.data);
    }
  };
  const handleShow = (id) => {
    setShow(true);
    getDataById(id);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedRecord(null);
  };
  const getDataById = async (id) => {
    setLoading(true);

    setTimeout(async () => {
      try {
        const config = localStorage.getItem("jwtToken");
        let data = {
          id,
        };

        const res = await userDetailsById(data, config);

        if (res?.status) {
          setSelectedRecord(res?.data);
        }
      } finally {
        setLoading(false);
      }
    }, 1000);
  };
  useEffect(() => {}, []);

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading d-flex align-items-center">
              <h2 className="mb-0">
                <b>User Details</b>
              </h2>
              <div className="d-inline-block align-items-center">
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <a href="/dashboard">
                        <i className="mdi mdi-home-outline"></i>
                      </a>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      User Details
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      User Details
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="active ml-auto back_btn">
                {" "}
                <Link className="btn_man" to="/dashboard">
                  Back
                </Link>
              </div>
            </div>
            <Modal
              show={show}
              onHide={() => setShow(false)}
              size="lg"
              dialogClassName="modal-100w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  User Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {loading ? (
                  <>
                    {[1, 2, 3].map((rowNumber) => (
                      <div key={rowNumber}>
                        <Row key={rowNumber}>
                          <Col xs={12} md={6}>
                            <p style={{ fontSize: "17px" }}></p>
                            <div>
                              <Skeleton active avatar title={{ rows: 1 }} />
                            </div>
                          </Col>
                          <Col xs={6} md={6}>
                            <h5>{rowNumber?.length}</h5>
                            <Skeleton
                              active
                              avatar
                              title={{ rows: rowNumber?.length }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                ) : selectedRecord ? (
                  selectedRecord?.map((field) => {
                    let date = field?.payment_date;
                    date = new Date(date * 1000);
                    return (
                      <Container>
                        <Row>
                          <Col xs={12} md={6}>
                            <p style={{ fontSize: "17px" }}> Name</p>
                            <div>
                              <p style={{ fontSize: "14px" }}>{field?.name}</p>
                            </div>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> S/W/D of Mr/Mrs/Ms</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.parent_or_husband_name}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6}>
                            <h5> Age</h5>
                            <p style={{ fontSize: "14px" }}>{field?.age}</p>
                          </Col>
                          <Col xs={6} md={6}>
                            <h5> Nationality</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.nationality}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Address</h5>
                            <p style={{ fontSize: "14px" }}>{field.address}</p>
                          </Col>
                          <Col xs={6} md={6}>
                            <h5> Email</h5>
                            <p style={{ fontSize: "14px" }}>{field?.email}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6}>
                            <h5> Contact Number</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.contact_number}
                            </p>
                          </Col>
                          <Col xs={12} md={6}>
                            <h5>Whatsapp Number</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.whatsapp_number}{" "}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <h5>Nominee Name</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.nominee_name}
                            </p>
                          </Col>

                          <Col xs={6} md={6}>
                            <h5> Relationship</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.relationship}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6}>
                            <h5> Nominee Contact Number</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.nominee_contact_number}
                            </p>
                          </Col>
                          <Col xs={12} md={6}>
                            <h5>Payment Details</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.payment_details}{" "}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6}>
                            <h5> Payment Date</h5>
                            <p style={{ fontSize: "14px" }}>
                              {moment(date).format("DD/MM/YYYY")}
                            </p>
                          </Col>
                          <Col xs={12} md={6}>
                            <h5>Utr Number</h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.utr_number}{" "}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={6} md={6}>
                            <h5>Payment Screenshot</h5>
                            <img
                              src={
                                baseUrls + "/static" + field?.payment_screenshot
                              }
                              alt=""
                            ></img>
                          </Col>
                          <Col xs={12} md={6}>
                            <h5>Paid Amount </h5>
                            <p style={{ fontSize: "14px" }}>
                              {field?.paid_amount}{" "}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          {" "}
                          <Col xs={6} md={6}>
                            <h5> Sponser Id</h5>
                            {/* <p style={{ fontSize: "14px" }}>{field?.email}</p> */}
                          </Col>
                        </Row>
                      </Container>
                    );
                  })
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose}>Close</Button>
              </Modal.Footer>
            </Modal>

            <div className="box">
              <div className="box-body">
                <div className="">
                  <div className="">
                    <div className="table-responsive">
                      <ReactDatatable
                        config={config}
                        records={record}
                        columns={columns}
                        onPageChange={pageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
