import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import configs from "../Constant/Config";
import { dashboardData } from "../services/user";

function Dashboard() {
  const [dataPoints, setDataPoints] = useState([]);
  const [count, setCount] = useState("");
  const [contestCreated, setcontestCreated] = useState("");
  // const [activeContest, setActiveContest] = useState("");
  const [todayCreateContest, setTodayCreateContest] = useState("");
  // const [inactiveContest, setInactiveContest] = useState("");
  const [totalParticipant, setTotalParticipant] = useState("");
  const [todayWithdraw, setTodayWithdraw] = useState("");
  const [totalReq, setTotalReq] = useState("");
  const [totalVerifiedUser, setTotalVerifiedUser] = useState("");
  const [totalPendingUser, setTotalPendingUser] = useState("");
  const [kycuser, setKycUser] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
      navigate("/");
    }
    getDashboardData();
  }, []);
  const getDashboardData = async () => {
    const config = localStorage.getItem("jwtToken");

    const res = await dashboardData(config);
    console.log(res, "jhjh");
    if (res.status) {
      setTotalParticipant(res?.data[0]?.totaluser);
      setTotalVerifiedUser(res?.verifiedUser[0]?.verifieduser)
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid p-0 mt-2">
            <div className="section-heading d">
              <h2 className="mb-0">
                <b>Dashboard</b>
              </h2>
            </div>

            <div className="row dashbord_man">
              <div className="col-md-3 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/user-details">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Participants</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                          <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {totalParticipant}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-2">
                              <span className="mdi mdi-account-group-outline"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-12 card-space">
            <div className="card card-animate">
              <Link to="/manage-payment">
              <div className="card-body">
                <p className="fw-medium mb-0">Verified User</p>
                <div className="d-flex justify-content-between count-outer">
                  <div>
                    <h2 className="ff-secondary fw-semibold">
                      <span className="counter-value" data-target={100}>
                     {totalVerifiedUser}
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title rounded-circle fs-2">
                      <span className="mdi mdi-currency-usd"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
          {/* <div className="col-md-3 col-sm-6 col-12 card-space">
            <div className="card card-animate">
              <Link to="/user-details">
              <div className="card-body">
                <p className="fw-medium mb-0">Staking</p>
                <div className="d-flex justify-content-between count-outer">
                  <div>
                    <h2 className="ff-secondary fw-semibold">
                      <span className="counter-value" data-target={100}>
                       55.00
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title rounded-circle fs-2">
                      <span className="mdi mdi-percent"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>

          <div className="col-md-3 col-sm-6 col-12 card-space">
            <div className="card card-animate">
              <Link to="/contest/today">
              <div className="card-body">
                <p className="fw-medium mb-0">Transactions</p>
                <div className="d-flex justify-content-between count-outer">
                  <div>
                    <h2 className="ff-secondary fw-semibold">
                      <span className="counter-value" data-target={100}>
                      25.00
                      </span>
                    </h2>
                  </div>
                  <div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title rounded-circle fs-2">
                      <span className="mdi mdi-server"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Dashboard;
