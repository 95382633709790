import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Form from "react-bootstrap/Form";
import { useParams, useNavigate } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";
import { userDetailsById, registrationUpdate } from "../../services/user";
export const UserEdit = () => {
  const initialValue = {
    firstApplicant: "",
    singleValue: "",
    additionalText: "",
    parentHusband: "",
    swdOf: "",
    // msMrs: "",
    combinedValue: "",
    bothValue: "",
    age: "",
    nationality: "",
    address: "",
    email: "",
    contactNumber: "",
    whatsappNumber: "",
    nomineeName: "",
    relationship: "",
    nomineeContactNumber: "",
    // signature: "",
    paymentDetails: "",
    paymentDate: "",
    utrNumber: "",
    paymentScreenshot: "",
    paidAmount: "",
    sponserId: "",
  };

  const [formData, setFormData] = useState(initialValue);
  const [errors, setErrors] = useState(initialValue);
  const [reffralCode, setReffralCode] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    let error = "";
    const regex = /^[6-9]\d{9}$/;

    const regexText = /^[A-Za-z\s]+$/;

    if (value === "") {
      error = "This field is required.";
    }

    if (name === "paymentScreenshot") {
      const file = e.target.files[0];

      setFormData({
        ...formData,
        [name]: file,
      });
    } else {
      // console.log("name",name)
      // console.log("value",value)
      // console.log(name === "firstApplicant" && !regexText.test(value),"change",value)
      if (name === "singleValue" && !regexText.test(value)) {
        error = "Enter valid format1";
      }
      if (name === "bothValue" && !regexText.test(value)) {
        error = "Enter valid format";
      }

      if (name === "age") {
        const age = Number(value);
        if (isNaN(age) || age < 0 || age > 100) {
          error = "Age must be a valid number with a maximum of 100.";
        }
      }

      if (name === "nationality" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "email" && value !== "") {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailPattern.test(value)) {
          error = "Invalid email format.";
        }
      }
      if (name === "contactNumber" && !regex.test(value)) {
        error = "Invalid Indian phone number.";
      }
      if (name === "whatsappNumber" && !regex.test(value)) {
        error = "Invalid Indian phone number.";
      }

      if (name === "nomineeName" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "relationship" && !regexText.test(value)) {
        error = "Enter valid format";
      }
      if (name === "nomineeContactNumber") {
        if (value && !regex.test(value)) {
          error = "Invalid Indian phone number.";
        }
      }

      if (name === "paymentScreenshot") {
        setFormData({
          ...formData,
          [name]: files[0],
        });

        if (name === "paymentScreenshot") {
          error = "";
        }
      }

      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: error,
      });
    }
  };

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const params = useParams();
  const { id } = params;

  useEffect(() => {
    getDataById();
  }, [id]);

  useEffect(() => {
    const prefixMap = {
      Mr: "Mr",
      Ms: "Ms",
      Mrs: "Mrs",
    };
    const prefix2 = {
      S: "S",
      W: "W",
      D: "D",
      Mr: "Mr",
      Mrs: "Mrs",
      Ms: "Ms",
    };

    const initialAdditionalText = formData.firstApplicant;

    let updatedAdditionalText = initialAdditionalText;

    for (const prefix in prefixMap) {
      if (initialAdditionalText.includes(prefix)) {
        updatedAdditionalText = prefixMap[prefix];
        break;
      }
    }

    const initialCombinedValue = formData.combinedValue;

    let updatedParentHusband = initialCombinedValue;
    let updatedSwdOf = initialCombinedValue;

    for (const prefix in prefix2) {
      if (initialCombinedValue.includes(prefix)) {
        if (["S", "W", "D"].includes(prefix)) {
          updatedParentHusband = prefix2[prefix];
        } else {
          updatedSwdOf = prefix2[prefix];
        }
      }
    }
    setFormData({
      ...formData,
      additionalText: updatedAdditionalText,
      parentHusband: updatedParentHusband,
      swdOf: updatedSwdOf,
    });
  }, [formData.firstApplicant, formData.combinedValue]);

  const getDataById = async () => {
    const config = localStorage.getItem("jwtToken");
    let data = {
      id,
    };
    const res = await userDetailsById(data, config);

    if (res.status) {
      const {
        name,
        parent_or_husband_name,
        contact_number,
        whatsapp_number,
        nominee_name,
        nominee_contact_number,
        payment_date,
        utr_number,
        payment_screenshot,
        paid_amount,
        sponsor_id,
        ...otherData
      } = res.data[0];
      let date = new Date(payment_date * 1000);
      date = moment(date).format("YYYY-MM-DD");

      const inputString = parent_or_husband_name;
      const updatedString = inputString.replace(/(D|S|W)\/(Mr|Mrs|Ms)\s/g, "");

      const inputString1 = name;
      const updatedString1 = inputString1.replace(/(Mr|Mrs|Ms)\s/g, "");

      const updatedFormData = {
        ...formData,
        firstApplicant: name,
        singleValue: updatedString1,
        combinedValue: parent_or_husband_name,
        bothValue: updatedString,
        contactNumber: contact_number,
        whatsappNumber: whatsapp_number,
        nomineeName: nominee_name,
        nomineeContactNumber: nominee_contact_number,
        paymentDetails: nominee_contact_number,
        paymentDate: date,
        utrNumber: utr_number,

        paymentScreenshot: payment_screenshot,
        paidAmount: paid_amount,

        ...otherData,
      };

      setFormData(updatedFormData);
      setReffralCode(sponsor_id);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    let isValid = true;

    const newErrors = {};
    let err = "This Field is required ";
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const regex = /^[6-9]\d{9}$/;

    const regexText = /^[A-Za-z\s]+$/;
    const regexTextSlash = /^[A-Za-z\s\/]+(\s\/)?[A-Za-z\s]+$/;

    const fieldsToValidate = [
      // "firstApplicant",
      "singleValue",
      // "combinedValue",
      "bothValue",
      "nationality",
      "address",
      "contactNumber",
      "whatsappNumber",
      "nomineeName",
      "relationship",
      "paymentDetails",
      "paymentDate",
      "utrNumber",
      "paidAmount",
    ];

    for (const key of fieldsToValidate) {
      if (!formData[key]) {
        newErrors[key] = "This field is required.";
        isValid = false;
        break;
      }

      if (key === "singleValue" && !regexText.test(formData.singleValue)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }

      if (key === "bothValue") {
        if (!regexTextSlash.test(formData[key])) {
          newErrors[key] = "Enter valid format";

          isValid = false;
        } else {
          newErrors[key] = "";
        }
        console.log(isValid, "isValid121");
      }
      if (formData.age && isNaN(formData.age)) {
        newErrors[key] = "Age must be a valid number.";
        isValid = false;
      }
      if (formData.age) {
        const age = Number(formData.age);
        if (isNaN(age) || age < 0 || age > 100) {
          newErrors["age"] = "Age cannot be greater then 100.";
          isValid = false;
        }
      }
      if (key === "nationality" && !regexText.test(formData.nationality)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }

      if (formData.email && !emailPattern.test(formData.email)) {
        newErrors["email"] = "Invalid email format.";
        isValid = false;
      }

      console.log(formData.contactNumber, "formData.contactNumber");
      if (key === "contactNumber" && !regex.test(formData.contactNumber)) {
        newErrors[key] = "Invalid Indian phone number.";
        isValid = false;
      }
      if (key === "whatsappNumber" && !regex.test(formData.whatsappNumber)) {
        newErrors[key] = "Invalid Indian phone number.";
        isValid = false;
      }

      if (key === "nomineeName" && !regexText.test(formData.nomineeName)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }
      if (key === "relationship" && !regexText.test(formData.relationship)) {
        newErrors[key] = "Enter valid format";
        isValid = false;
      }

      if (
        formData.nomineeContactNumber &&
        !regex.test(formData.nomineeContactNumber)
      ) {
        newErrors["nomineeContactNumber"] = "Invalid Indian phone number.";
        isValid = false;
      }
      // if (formData.paymentScreenshot) {
      //   if (!formData.paymentScreenshot.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      //     newErrors["paymentScreenshot"] = "Please enter a valid image format";
      //     isValid = false;
      //   }
      // } else {
        
      //   delete newErrors["paymentScreenshot"];
      // }
    }

    setErrors(newErrors);
   

    if (isValid) {
      const {
        firstApplicant,
        singleValue,
        additionalText,
        parentHusband,
        swdOf,
        bothValue,

        combinedValue,
        age,
        nationality,
        address,
        email,
        contactNumber,
        whatsappNumber,
        nomineeName,
        relationship,
        nomineeContactNumber,
        paymentDetails,
        paymentDate,
        utrNumber,
        paymentScreenshot,

        paidAmount,
      } = formData;
      try {
        const formDatas = new FormData();

        // formDatas.append("name", firstApplicant);
        formDatas.append("name", `${additionalText} ${singleValue}`);

        formDatas.append(
          "parent_or_husband_name",
          `${parentHusband}/${swdOf} ${bothValue}`
        );
        formDatas.append("age", age);
        formDatas.append("nationality", nationality);
        formDatas.append("address", address);
        formDatas.append("email", email);
        formDatas.append("contact_number", contactNumber);
        formDatas.append("whatsapp_number", whatsappNumber);
        formDatas.append("nominee_name", nomineeName);
        formDatas.append("relationship", relationship);
        formDatas.append("nominee_contact_number", nomineeContactNumber);
        // formDatas.append("signature", signature);
        formDatas.append("payment_details", paymentDetails);
        formDatas.append("payment_date", paymentDate);
        formDatas.append("utr_number", utrNumber);
        formDatas.append("paymentScreenshot", paymentScreenshot);
        formDatas.append("paid_amount", paidAmount);
        formDatas.append("reffralCode", reffralCode);
        formDatas.append("id", id);
        const config = localStorage.getItem("jwtToken");

        const res = await registrationUpdate(formDatas, config);

        if (res.status) {
          toast.dismiss();

          toast.success(res.message);
          navigate("/user-details");
        } else {
          toast.dismiss();
          toast.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validNumber = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const spaceCheck = (e) => {
    if (/^\s/.test(e.key)) {
      e.preventDefault();
    }
  };

  const copyContactNumberToWhatsapp = () => {
    const checkbox = document.getElementById("fillContactNumberCheckbox");

    if (checkbox.checked) {
      setFormData({
        ...formData,
        whatsappNumber: formData.contactNumber,
      });

      setErrors({
        ...errors,
        whatsappNumber: "",
      });
    } else {
      setFormData({
        ...formData,
        whatsappNumber: "",
      });

      setErrors({
        ...errors,
        whatsappNumber: "This field is required",
      });
    }
  };

  const handleChangeForWhatsup = (e) => {
    const { name, value } = e.target;
    const regex = /^[6-9]\d{9}$/;
    let error = "";

    console.log(name, "name1111");
    if (name === "whatsappNumber" && !regex.test(value)) {
      error = "Invalid Indian phone number.";
    } else {
      error = "";
    }

    const newValue = {
      ...formData,
      [name]: value,
      whatsappNumber: value,
    };
    setFormData(newValue);
    setErrors({
      ...errors,
      [name]: error,
    });
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading d-flex align-items-center">
                <h2 className="mb-0">
                  <b>Update Registration Form</b>
                </h2>
                <div className="d-inline-block align-items-center">
                  <nav>
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/dashboard">
                          <i className="mdi mdi-home-outline"></i>
                        </a>
                      </li>
                      <li className="breadcrumb-item" aria-current="page">
                        Update Registration Form
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Update Registration Form
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
              <div className="launchpad_box">
                <Form>
                  <div className="row">
                    <Form.Group className="col-md-6 col-12">
                      <div className="form-group">
                        <Form.Label>Name</Form.Label>
                        <div>
                          <div>
                            <Form.Check
                              inline
                              type="radio"
                              label="Mr"
                              name="additionalText"
                              value="Mr"
                              checked={formData.additionalText === "Mr"}
                              onChange={handleChangeRadio}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="Mrs"
                              name="additionalText"
                              value="Mrs"
                              checked={formData.additionalText === "Mrs"}
                              onChange={handleChangeRadio}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              label="Ms"
                              name="additionalText"
                              value="Ms"
                              checked={formData.additionalText === "Ms"}
                              onChange={handleChangeRadio}
                            />
                          </div>
                        </div>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            {formData.additionalText}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="singleValue"
                            value={formData.singleValue}
                            onChange={handleChange}
                            // onKeyPress={spaceCheck}
                          />
                        </InputGroup>
                        {errors.singleValue && (
                          <span style={{ color: "red" }}>
                            {errors.singleValue}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group className="col-md-6 col-12">
                      <div className="form-group">
                        <Form.Label>S/W/D of Mr/Mrs/Ms</Form.Label>

                        <div>
                          <Form.Check
                            inline
                            type="radio"
                            label="S"
                            name="parentHusband"
                            value="S"
                            checked={formData.parentHusband === "S"}
                            onChange={handleChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            label="W"
                            name="parentHusband"
                            value="W"
                            checked={formData.parentHusband === "W"}
                            onChange={handleChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            label="D"
                            name="parentHusband"
                            value="D"
                            checked={formData.parentHusband === "D"}
                            onChange={handleChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            label="Mr"
                            className="more-space"
                            name="swdOf"
                            value="Mr"
                            checked={formData.swdOf === "Mr"}
                            onChange={handleChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            label="Mrs"
                            name="swdOf"
                            value="Mrs"
                            checked={formData.swdOf === "Mrs"}
                            onChange={handleChange}
                          />
                          <Form.Check
                            inline
                            type="radio"
                            label="Ms"
                            name="swdOf"
                            value="Ms"
                            checked={formData.swdOf === "Ms"}
                            onChange={handleChange}
                          />
                        </div>

                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            {`${formData.parentHusband}/${formData.swdOf}`}
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            name="bothValue"
                            value={formData.bothValue}
                            // onKeyPress={spaceCheck}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        {errors.bothValue && (
                          <span style={{ color: "red" }}>
                            {errors.bothValue}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="age"
                      className="col-md-4 col-sm-6 col-12"
                    >
                      <div className="form-group">
                        <Form.Label>Age</Form.Label>
                        <Form.Control
                          type="text"
                          name="age"
                          value={formData.age}
                          onKeyPress={validNumber}
                          onChange={handleChange}
                        />
                        {errors.age && (
                          <span style={{ color: "red" }}>{errors.age}</span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="nationality"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                          type="text"
                          name="nationality"
                          value={formData.nationality}
                          onKeyPress={spaceCheck}
                          onChange={handleChange}
                        />
                        {errors.nationality && (
                          <span style={{ color: "red" }}>
                            {errors.nationality}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="address"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          type="text"
                          name="address"
                          value={formData.address}
                          // onKeyPress={spaceCheck}
                          onChange={handleChange}
                        />
                        {errors.address && (
                          <span style={{ color: "red" }}>{errors.address}</span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group controlId="email" className="col-md-4 col-sm-6">
                      <div className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          onKeyPress={spaceCheck}
                        />
                        {errors.email && (
                          <span style={{ color: "red" }}>{errors.email}</span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="contactNumber"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Contact Number</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            +91
                          </InputGroup.Text>
                          <Form.Control
                            type="tel"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onKeyPress={validNumber}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        {errors.contactNumber && (
                          <span style={{ color: "red" }}>
                            {errors.contactNumber}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="whatsappNumber"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Whatsapp Number</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            +91
                          </InputGroup.Text>
                          <Form.Control
                            type="tel"
                            name="whatsappNumber"
                            value={formData.whatsappNumber}
                            onKeyPress={validNumber}
                            onChange={handleChangeForWhatsup}
                          />
                        </InputGroup>

                        <div className="form-group form-check m-0">
                          <span onClick={copyContactNumberToWhatsapp}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="fillContactNumberCheckbox"
                            />
                          </span>
                          <label
                            className="form-check-label"
                            htmlFor="fillContactNumberCheckbox"
                            // onClick={copyContactNumberToWhatsapp}
                            style={{ cursor: "pointer" }}
                          >
                            Fill in Contact Number
                          </label>
                        </div>

                        {errors.whatsappNumber && (
                          <span style={{ color: "red" }}>
                            {errors.whatsappNumber}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="nomineeName"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Nominee's Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="nomineeName"
                          value={formData.nomineeName}
                          // onKeyPress={spaceCheck}
                          onChange={handleChange}
                        />
                        {errors.nomineeName && (
                          <span style={{ color: "red" }}>
                            {errors.nomineeName}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="relationship"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Relationship</Form.Label>
                        <Form.Control
                          type="text"
                          name="relationship"
                          value={formData.relationship}
                          // onKeyPress={spaceCheck}
                          onChange={handleChange}
                        />
                        {errors.relationship && (
                          <span style={{ color: "red" }}>
                            {errors.relationship}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="nomineeContactNumber"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Nominee's Contact Number</Form.Label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text id="basic-addon1">
                            +91
                          </InputGroup.Text>
                          <Form.Control
                            type="tel"
                            name="nomineeContactNumber"
                            value={formData.nomineeContactNumber}
                            onKeyPress={validNumber}
                            onChange={handleChange}
                          />
                        </InputGroup>

                        {errors.nomineeContactNumber && (
                          <span style={{ color: "red" }}>
                            {errors.nomineeContactNumber}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="paymentDetails"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Payment details</Form.Label>
                        <Form.Control
                          type="text"
                          name="paymentDetails"
                          value={formData.paymentDetails}
                          // onKeyPress={spaceCheck}
                          onChange={handleChange}
                        />
                        {errors.paymentDetails && (
                          <span style={{ color: "red" }}>
                            {errors.paymentDetails}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="paymentDate"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Payment Date</Form.Label>
                        <Form.Control
                          type="date"
                          name="paymentDate"
                          value={formData.paymentDate}
                          onKeyDown={(e) => e.preventDefault()}
                          onChange={handleChange}
                        />
                        {errors.paymentDate && (
                          <span style={{ color: "red" }}>
                            {errors.paymentDate}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="utrNumber"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>UTR Number</Form.Label>
                        <Form.Control
                          type="text"
                          name="utrNumber"
                          value={formData.utrNumber}
                          onKeyPress={spaceCheck}
                          onChange={handleChange}
                        />
                        {errors.utrNumber && (
                          <span style={{ color: "red" }}>
                            {errors.utrNumber}
                          </span>
                        )}
                      </div>
                    </Form.Group>

                    <Form.Group
                      controlId="paymentScreenshot"
                      className="col-md-4 col-sm-6"
                    >
                      <div className="form-group">
                        <Form.Label>Payment Screenshot</Form.Label>
                        <Form.Control
                          type="file"
                          name="paymentScreenshot"
                          onChange={handleChange}
                        />
                        {errors.paymentScreenshot && (
                          <span style={{ color: "red" }}>
                            {errors.paymentScreenshot}
                          </span>
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="paidAmount"
                      className="col-md-4 col-sm-6"
                    >
                      {" "}
                      <div className="form-group">
                        <Form.Label>Paid Amount</Form.Label>
                        <Form.Control
                          type="number"
                          name="paidAmount"
                          value={formData.paidAmount}
                          onChange={handleChange}
                          onKeyPress={validNumber}
                        />
                        {errors.paidAmount && (
                          <span style={{ color: "red" }}>
                            {errors.paidAmount}
                          </span>
                        )}{" "}
                      </div>
                    </Form.Group>
                    <Form.Group
                      controlId="sponserId"
                      className="col-md-4 col-sm-6"
                    >
                      {" "}
                      <div className="form-group">
                        <Form.Label>Sponser Id</Form.Label>
                        <Form.Control
                          type="text"
                          name="sponserId"
                          value={reffralCode}
                          onKeyPress={spaceCheck}
                          onChange={(e) => setReffralCode(e.target.value)}
                        />
                        {/* {reffralCodeErr && (
                      <span style={{ color: "red" }}>{reffralCodeErr}</span>
                    )}{" "} */}
                      </div>
                    </Form.Group>
                  </div>
                </Form>
                <button className="btn btn-info mt-2" onClick={updateHandler}>
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
