const sideMenuList = [
  {
    id: 1,
    title: "Dashboard",
    route: "/dashboard",
    icon: "mdi mdi-speedometer",
  },
  {
    id: 2,
    title: "UserDetails",
    route: "/user-details",
    icon: "mdi mdi-account-outline",
  },
  {
    id: 2,
    title: "PaymentDetails",
    route: "/payment-details",
    icon: "mdi mdi-account-outline",
  },

  
  // {
  //   id: 3,
  //   title: "Snapshot",
  //   route: "/snapshot",
  //   icon: "mdi mdi-account-outline",
  // },
  // {
  //   id: 3,
  //   title: "Admin  Details",
  //   route: "/admin-details",
  //   icon: "mdi mdi-account-outline",
  // },
  // {
  //   id: 4,
  //   title: "Project Interests",
  //   route: "/ido-request",
  //   icon: "mdi mdi-card-account-details-outline",
  //   // subMenu: [
  //   //   {
  //   //     id: 1,
  //   //     title: " Ido Request",
  //   //     route: "/ido-request",
  //   //     icon: "mdi  mdi-arrow-right",
  //   //   },
  //   // ],
  // },
  // {
  //   id: 5,
  //   title: "Approved",
  //   route: "/approve",
  //   icon: "mdi mdi-check-circle-outline",
  // },
  // {
  //   id: 6,
  //   title: "Disapproved",
  //   route: "/reject",
  //   icon: "mdi mdi-close-circle-outline",
  // },
  // {
  //   id: 7,
  //   title: "Contact Us",
  //   route: "/contact-us",
  //   icon: "mdi mdi-speedometer",
  // },
  // {
  //   id: 8,
  //   title: "Profile",
  //   route: "/user-profile",
  //   icon: "mdi mdi-card-account-details-outline",
  // },
  // {
  //   id: 9,
  //   title: "IDO Allocation",
  //   route: "/ido-allocation",
  //   icon: "mdi mdi-card-account-details-outline",
  // },

  // // {
  // //   id: 3,
  // //   title: "Transaction",
  // //   route: "",
  // //   icon: "mdi mdi-wallet-outline",
  // //   subMenu: [
  // //     {
  // //       id: 1,
  // //       title: "Transaction",
  // //       route: "/transaction",
  // //       icon: "mdi  mdi-arrow-right",
  // //     }
  // //   ],
  // // },
  // {
  //   id: 10,
  //   title: "IDO Calendar",
  //   route: "/calendar",
  //   icon: "mdi  mdi-arrow-right",
  // },

  // // {
  // //   id: 10,
  // //   title: "Logout",
  // //   route: "/",
  // //   icon: "mdi mdi-logout-variant",
  // // },
];
export default sideMenuList;
