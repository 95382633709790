import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { login, getCodeHandle } from "../services/auth/auth";
import { Link, useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import env from "react-dotenv";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, seteye] = useState("fa-eye-slash");
  const [showCurrentPassword, setShowCurrentPassword] = useState("password");
  const form = useRef();

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) {
    } else {
      navigate("/dashboard");
    }
  }, []);

  const showcurrentPassword = () => {
    if (showCurrentPassword === "password") {
      setShowCurrentPassword("text");
      seteye("fa-eye");
    } else {
      setShowCurrentPassword("password");
      seteye("fa-eye-slash");
    }
  };

  const loginInputTypes = async (type, event) => {
    console.log(email, "emailrw");
    if (type === "email") {
      setEmail(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const handleOnBlurEmail = async (event) => {
    var eventValue = await event.target.value;
    var Email1Reg = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      email
    );
    if (!eventValue) {
      toast.dismiss();
      toast.error("Enter Email Address");
      return false;
    }

    if (!Email1Reg) {
      toast.dismiss();
      toast.error("Enter valid Email Address");
      return false;
    }
  };
  const handleOnBlurPassword = async (event) => {
    var password = await event.target.value;
    if (!password) {
      toast.dismiss();
      toast.error("Password is required");
      return false;
    }
  };

  const onLogin = async (e) => {
    e.preventDefault();
    let loginData = {};

    if (!password) {
      toast.error("Password  are required");
      return;
    }

    loginData = {
      email,
      password,
    };

    const resp = await login(loginData);

    if (resp.status === true) {
      let token = resp.token;

      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userType", resp.user_type);

      toast.success("Login Successfully");
      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } else {
      toast.error(resp.message);
    }
  };

  return (
    <div className="login d-flex nm-aic nm-vh-md-100">
      <div className="Background_container__KTfIm">
        <div className="Background_ElipL__xhjwR"></div>
      </div>
      <div className="container  m-auto">
        <div className="row   align-items-center">
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <img
              src="assets/images/loginvector.png"
              alt=""
              className="admin_home_img img-fluid"
            />
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-4">
            <div className="nm-tm-wr">
              <div className="container p-0">
                <div className="container p-0">
                  {/* <img
                    src="assets/images/logo-coin.png"
                    alt=""
                    className="img-fluid login_logo"
                  /> */}
                </div>
                <h4 className="text-center text-white mb-5">Admin Login</h4>
                <form ref={form}>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="inputUsername"
                      tabIndex={1}
                      placeholder="Email"
                      required=""
                      onChange={(e) => loginInputTypes("email", e)}
                      onBlur={handleOnBlurEmail}
                    />
                  </div>
                  <div className=" mb-3 password_login">
                    <>
                      <input
                        type={showCurrentPassword}
                        className="form-control password"
                        id="inputPassword"
                        tabIndex={2}
                        placeholder="Password"
                        required=""
                        onChange={(e) => loginInputTypes("password", e)}
                        onBlur={handleOnBlurPassword}
                      />
                      <span
                        role="button"
                        onClick={showcurrentPassword}
                        className="eye-icon"
                      >
                        <i className={`fa ${eye}`}></i>
                      </span>
                      {/* <input
                          type="text"
                          className="form-control mt-3"
                          id="inputPin"
                          placeholder=" Enter PIN"
                          required=""
                          onChange={handleChange}
                        />
                        {pinErr && (
                          <span style={{ color: "red" }} className="mb-5">
                            {pinErr}
                          </span>
                        )} */}
                    </>
                  </div>

                  <div className=" nm-aic nm-mb-1">
                    <div className="login-btn ">
                      <button
                        type="submit"
                        className="btn btn_man nm-hvr w100"
                        onClick={onLogin}
                      >
                        Log In
                      </button>
                    </div>
                  </div>
                  {/* <div className="text-center mt-3">
              
                      <Link to="" onClick={() => setLoginMethod("password")}>
                        Use Password
                      </Link>
                  
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
