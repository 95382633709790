import { baseUrl } from "./BaseUrl";

export const logins = baseUrl + "/login-admin";
export const registerData = baseUrl + "/register-details";
export const usetrDataById = baseUrl + "/get-user-details-by-id";
export const invoices = baseUrl + "/invoice";
export const dashboardDatas = baseUrl+"/dashboard-data";
export const registrationUpdates = baseUrl+"/registration-update";



// export const getCodes = baseUrl + "/getCode";
// export const changePassswords = baseUrl + "/changePassword";
// export const idoRequests = baseUrl + "/get-ido-request";
// export const statusUpdate = baseUrl + "/status-update";

// export const projectDetails = baseUrl + "/get-project-details";
// export const IdoAdd = baseUrl + "/project-ido-add-update";
// export const getIdoDatas = baseUrl + "/get-ido-data";
// export const adminAdd = baseUrl + "/admin-create";
// export const getAdmin = baseUrl + "/get-admin-data";
// export const actionHandle = baseUrl + "/action-activities";
// export const adminDataByIds = baseUrl + "/admin-data-by-id";
// export const getContactUs = baseUrl + "/get-contact-us";
// export const projectIdoByIds = baseUrl+"/project-ido-by-id";
// export const projectIdoUpdates = baseUrl+"/project-ido-update";
// export const idoAllocationAddUpdate = baseUrl+"/ido-allocation-manage";
// export const idoAllocationdataByIds = baseUrl+"/ido-allocation-data-by-id";
// export const getSnapshots = baseUrl+"/get-snapshot-data";
// export const getIdoAllocations = baseUrl+"/get-ido-allocation-data";











